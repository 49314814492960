import React from "react"
import GalleryElement from 'react-photo-gallery';
import "../App.css"
import "./Gallery.css"
import NavBar from "../components/NavBar"

import japan13 from "../gallery-images/JAPAN-13.jpg"
import japan23 from "../gallery-images/JAPAN-23.jpg"
import japan36 from "../gallery-images/JAPAN-36.jpg"
import japan45 from "../gallery-images/JAPAN-45.jpg"
import japan54 from "../gallery-images/JAPAN-54.jpg"
import japan58 from "../gallery-images/JAPAN-58.jpg"

export default function Gallery(props){
    const photos = [
        {
            src: japan13,
            width:4,
            height:3
        },
        {
            src: japan23,
            width:2,
            height:3
        },
        {
            src: japan36,
            width:4,
            height:3
        },
        {
            src: japan45,
            width:2,
            height:3
        },
        {
            src: japan54,
            width:4,
            height:3
        },
        {
            src: japan58,
            width:2,
            height:3
        }

    ];

    return (
        <div className="Gallery">
            <NavBar/>
            <div class="title"><h1>Shot on Film.</h1></div>
            <GalleryElement photos={photos}/>
        </div>
    );
}