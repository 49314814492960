import React from "react"
import "../App.css"
import "./NavBar.css"
// import background from "./background-image.jpg"

export default function NavBar(props) {
    return (
        <div className="NavBar">
            {/* <img class="header-image" src={background} alt="Background"/> */}
            <div className="name">
                <a href="/">Lukas Hoffman</a>
            </div>
            <div className="links">
                <a href="/about">About</a>
                <a href="/gallery">Gallery</a>
                <a href="/projects">Projects</a>
            </div>
        </div>
    );
}