import React from "react"
import "../App.css"
import "./Home.css"
import NavBar from "../components/NavBar"
import background from "../components/background-image.jpg"

export default function Home(props){
    return (
        <div className="Home">
            <img class="header-image" src={background} alt="Background"/>
            <NavBar/>
            <div class="intro">
                <div><h1 class="animation">I'm Lukas.</h1></div>
                <div><h2 class="subhead">C/C++ developer & more.</h2></div>
            </div>
        
        </div>
    );
}